import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"faq"} />
		<Helmet>
			<title>
				FAQ | Часті запитання на Mobivgo
			</title>
			<meta name={"description"} content={"Опановуємо гру, одне запитання за раз - відповіді на ваші запитання про теніс"} />
			<meta property={"og:title"} content={"FAQ | Часті запитання на Mobivgo"} />
			<meta property={"og:description"} content={"Опановуємо гру, одне запитання за раз - відповіді на ваші запитання про теніс"} />
			<meta property={"og:image"} content={"https://mobivgo.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://mobivgo.com/img/43623623.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://mobivgo.com/img/43623623.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://mobivgo.com/img/43623623.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://mobivgo.com/img/43623623.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://mobivgo.com/img/43623623.png"} />
			<meta name={"msapplication-TileImage"} content={"https://mobivgo.com/img/43623623.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="100px 0 100px 0" background="#ffffff" quarkly-title="FAQ-5">
			<Text margin="0px 0px 15px 0px" font="normal 600 42px/1.2 --fontFamily-sans" color="--darkL1">
			Відповіді на запитання
			</Text>
			<Text margin="0px 0px 70px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#555a5f" sm-margin="0px 0px 50px 0px">
			Поки ви готуєтеся вийти на корти Mobivgo, ми склали список поширених запитань, які допоможуть згладити ваш шлях до тенісної досконалості.
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="50px 50px"
				md-grid-template-columns="1fr"
				sm-grid-gap="35px 0"
			>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
					Які програми доступні для початківців?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
					Ми пропонуємо ряд програм для початківців, зосереджених на основах гри, включаючи групові клініки та приватні уроки, щоб ви могли почати з правильної ноги.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
					Чи є у вас програми для просунутих гравців?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
					Так, просунуті гравці можуть скористатися спеціалізованими клініками, високоінтенсивними тренуваннями та можливостями змагальної гри, призначеними для відточування навичок та стратегії.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
					Чи можу я забронювати корт для особистого користування?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
					Безумовно! Члени та гості можуть забронювати наші криті та відкриті корти для особистої гри, тренувань або матчів. Зв'яжіться з нами, щоб дізнатися про наявність вільних місць та деталі бронювання.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
					Яке спорядження мені потрібно взяти з собою?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
					Хоча ми рекомендуємо приносити власну ракетку для комфорту та особистих уподобань, у нас є вибір ракеток, які можна взяти напрокат. Необхідно мати відповідний тенісний одяг та немарке тенісне взуття.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
					Чи існують вікові обмеження для участі в програмах?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
					Наші програми розраховані на будь-який вік, від дитячих розвиваючих занять для малюків до клінік і ліг для дорослих. Кожна програма розроблена відповідно до віку та рівня підготовки учасників.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
					Чи пропонуєте ви індивідуальні тренування?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
					Так, індивідуальні тренування доступні для гравців, які бажають отримати індивідуальну увагу для покращення певних аспектів своєї гри. Заняття з нашими сертифікованими тренерами можна забронювати відповідно до вашого розкладу.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-11">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					sm-font="--headline3"
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					md-text-align="left"
				>
					Готові до першого уроку?
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					Якщо ви не знайшли відповіді на своє запитання, будь ласка, не соромтеся звертатися до нас. Наша команда готова надати вам будь-яку додаткову інформацію, щоб зробити ваш досвід в Mobivgo незабутнім.
				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					width="100%"
					height="auto"
					padding="0px 0px 70% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
				>
					<Image
						position="absolute"
						width="100%"
						top="auto"
						left={0}
						src="https://mobivgo.com/img/9.jpg"
						object-fit="cover"
						display="block"
						right={0}
						bottom="0px"
						min-height="100%"
					/>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});